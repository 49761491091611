import { ref } from 'vue'

export const checkboxList: any = ref([
  {
    title: '默认',
    value: ['usdSales', 'orderCount', 'buyerCount', 'avgBuyer'],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '销售额(USD)',
        dataIndex: 'usdSales',
        key: 'usdSales',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '总收入(USD)',
        dataIndex: 'usdRevenue',
        key: 'usdRevenue',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '付费人均付费(USD)',
        dataIndex: 'usdAvgRevenueBuyer',
        key: 'usdAvgRevenueBuyer',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '人均客单价(USD)',
        dataIndex: 'usdAvgRevenue',
        key: 'usdAvgRevenue',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '销售额(CNY)',
        dataIndex: 'cnySales',
        key: 'cnySales',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '总收入(CNY)',
        dataIndex: 'cnyRevenue',
        key: 'cnyRevenue',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '付费人均付费(CNY)',
        dataIndex: 'cnyAvgRevenueBuyer',
        key: 'cnyAvgRevenueBuyer',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '人均客单价(CNY)',
        dataIndex: 'cnyAvgRevenue',
        key: 'cnyAvgRevenue',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '总订单',
        dataIndex: 'orderCount',
        key: 'orderCount',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '付费用户数',
        dataIndex: 'buyerCount',
        key: 'buyerCount',
        sorter: true,
        width: 100,
        checked: false,
      },
      {
        title: '付费人均付费次数',
        dataIndex: 'avgBuyer',
        key: 'avgBuyer',
        sorter: true,
        width: 100,
        checked: false,
      },
    ],
  },
  {
    title: '新用户',
    value: ['firstBuyerCount', 'firstBuyerRatio'],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '首次付费用户',
        dataIndex: 'firstBuyerCount',
        key: 'firstBuyerCount',
        sorter: true,
        width: 140,
        checked: false,
      },
      {
        title: '首次付费占比',
        dataIndex: 'firstBuyerRatio',
        key: 'firstBuyerRatio',
        sorter: true,
        width: 140,
        checked: false,
      },
    ],
  },
  {
    title: '退费',
    value: [],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '退费金额',
        dataIndex: 'refundAmount',
        key: 'refundAmount',
        sorter: true,
        width: 140,
        checked: false,
      },
      {
        title: '退费订单数',
        dataIndex: 'refundOrderCount',
        key: 'refundOrderCount',
        sorter: true,
        width: 140,
        checked: false,
      },
    ],
  },
])

export const basicColumns: any = [
  {
    title: '时间',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    sorter: true,
    fixed: 'left',
  },
  {
    title: '应用名',
    dataIndex: 'appName',
    key: 'appName',
    fixed: 'left',
  },
  {
    title: '包名',
    dataIndex: 'pkg',
    key: 'pkg',
  },
  {
    title: '支付渠道',
    dataIndex: 'payChnl',
    key: 'payChnl',
  },
  {
    title: '国家',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: '应用版本',
    dataIndex: 'appVersion',
    key: 'appVersion',
  },
  {
    title: '商品信息',
    dataIndex: 'prodName',
    key: 'prodName',
  },
  {
    title: '销售额(USD)',
    dataIndex: 'usdSales',
    key: 'usdSales',
    sorter: true,
  },
  {
    title: '总收入(USD)',
    dataIndex: 'usdRevenue',
    key: 'usdRevenue',
    sorter: true,
  },
  {
    title: '付费人均付费(USD)',
    dataIndex: 'usdAvgRevenueBuyer',
    key: 'usdAvgRevenueBuyer',
  },
  {
    title: '人均客单价(USD)',
    dataIndex: 'usdAvgRevenue',
    key: 'usdAvgRevenue',
  },
  {
    title: '销售额(CNY)',
    dataIndex: 'cnySales',
    key: 'cnySales',
    sorter: true,
  },
  {
    title: '总收入(CNY)',
    dataIndex: 'cnyRevenue',
    key: 'cnyRevenue',
    sorter: true,
  },
  {
    title: '付费人均付费(CNY)',
    dataIndex: 'cnyAvgRevenueBuyer',
    key: 'cnyAvgRevenueBuyer',
  },
  {
    title: '人均客单价(CNY)',
    dataIndex: 'cnyAvgRevenue',
    key: 'cnyAvgRevenue',
  },
  {
    title: '总订单',
    dataIndex: 'orderCount',
    key: 'orderCount',
  },
  {
    title: '付费用户数',
    dataIndex: 'buyerCount',
    key: 'buyerCount',
  },
  {
    title: '付费人均付费次数',
    dataIndex: 'avgBuyer',
    key: 'avgBuyer',
  },
  {
    title: '首次付费用户',
    dataIndex: 'firstBuyerCount',
    key: 'firstBuyerCount',
  },
  {
    title: '首次付费占比',
    dataIndex: 'firstBuyerRatio',
    key: 'firstBuyerRatio',
  },
  {
    title: '退费金额',
    dataIndex: 'refundAmount',
    key: 'refundAmount',
  },
  {
    title: '退费订单数',
    dataIndex: 'refundOrderCount',
    key: 'refundOrderCount',
  },
]
