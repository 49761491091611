/*
 * @Author: xuhuang xuhuang@wedobest.com.cn
 * @Date: 2023-03-15 16:57:58
 * @LastEditors: xuhuang xuhuang@wedobest.com.cn
 * @LastEditTime: 2023-05-19 17:54:47
 * @FilePath: useCheckbox.js
 * @Description:
 */


  import { ref, nextTick,onMounted } from "vue";
// import { checkboxList } from "../data";
  // type =1 默认是表头
  // type =2 是导出
  import _ from "lodash"
export default function useCheckbox (emit,type=1,list) {

  const showCheckList = ref([]);
  const checkboxList = ref(_.cloneDeep(list));
const visibleChange = () => {};
  const isCheckAll = ref(false);
  const isIndeterminate = ref(false);
  onMounted(() => {
    // if (localStorage.getItem('app_data_column')&& type==1) {
    //   checkboxList.value = JSON.parse(localStorage.getItem('app_data_column'))
    // }
  checkboxList.value.map(item => {
    if (item.value.length == item.children.length) {
      item.checkAll = true;
      item.indeterminate =false
    } else if (item.value.length == 0) {
      item.checkAll = false
      item.indeterminate = false
    } else {
      item.checkAll = item.checkAll;
      item.indeterminate = item.indeterminate;
    }
  })
  getShowList(true)
})
const onCheckAllChange = (e, group) => {
  console.log(e, group);
  if (!e.target.checked) {
    group.checkAll = false;
    group.indeterminate = false;
    group.value = [];
  } else {
    group.checkAll = true;
    group.indeterminate = false;
    group.value = group.children.map((item) => item.key);
  }
  nextTick(() => {
    getShowList(e.target.checked);
  });
};
const getShowList = (tag ) => {
  let temp = [];
  checkboxList.value.forEach((item) => {
    item.value.forEach((checkOption) => {
      item.children.forEach((showOption) => {
        if (showOption.key == checkOption) {
          temp.push(showOption.title);
        }
      });
    });
  });
  showCheckList.value = temp;
  if (!tag ){
    isCheckAll.value = false;
    if (temp.length == 0) {
      isIndeterminate.value = false;
    } else {
      isIndeterminate.value = true;
    }

  } else {
    let arr = 0;
    checkboxList.value.forEach(item => {
      arr +=item.children.length
    })
    if (showCheckList.value.length == arr) {
      isCheckAll.value = true;
      isIndeterminate.value = false;
    } else {
      isCheckAll.value = false;
      isIndeterminate.value = true;
    }
  }
  // type==1&& localStorage.setItem('app_data_column',JSON.stringify(checkboxList.value))
  emit("getColumn", {list:checkboxList.value,type});
};
const handleChange = (e, group) => {
  console.log(e.target.checked, group);
  if (group.children.length == group.value.length && e.target.checked) {
    group.indeterminate = false;
    group.checkAll = true;
  } else if (group.value.length == 1 && !e.target.checked) {
    group.indeterminate = false;
    group.checkAll = false;
  } else if (
    group.value.length == group.children.length - 1 &&
    e.target.checked
  ) {
    group.indeterminate = false;
    group.checkAll = true;
  } else if (group.value.length == group.children.length && !e.target.checked) {
    group.checkAll = false;
    group.indeterminate = true;
  } else {
    group.checkAll = false;
    group.indeterminate = true;
  }


  nextTick(() => {
    getShowList(e.target.checked );
  });
  };
  const handleAll = (e) => {
    let arr = [
      {
        title: '默认',
        value: ['usdSales', 'usdRevenue', 'usdAvgRevenueBuyer', 'usdAvgRevenue', 'cnySales', 'cnyRevenue', 'cnyAvgRevenueBuyer', 'cnyAvgRevenue', 'orderCount', 'buyerCount', 'avgBuyer'],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '销售额(USD)',
            dataIndex: 'usdSales',
            key: 'usdSales',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '总收入(USD)',
            dataIndex: 'usdRevenue',
            key: 'usdRevenue',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '付费人均付费(USD)',
            dataIndex: 'usdAvgRevenueBuyer',
            key: 'usdAvgRevenueBuyer',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '人均客单价(USD)',
            dataIndex: 'usdAvgRevenue',
            key: 'usdAvgRevenue',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '销售额(CNY)',
            dataIndex: 'cnySales',
            key: 'cnySales',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '总收入(CNY)',
            dataIndex: 'cnyRevenue',
            key: 'cnyRevenue',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '付费人均付费(CNY)',
            dataIndex: 'cnyAvgRevenueBuyer',
            key: 'cnyAvgRevenueBuyer',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '人均客单价(CNY)',
            dataIndex: 'cnyAvgRevenue',
            key: 'cnyAvgRevenue',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '总订单',
            dataIndex: 'orderCount',
            key: 'orderCount',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '付费用户数',
            dataIndex: 'buyerCount',
            key: 'buyerCount',
            sorter: true,
            width: 100,
            checked: false
          },
          {
            title: '付费人均付费次数',
            dataIndex: 'avgBuyer',
            key: 'avgBuyer',
            sorter: true,
            width: 100,
            checked: false
          }
        ]
      },
      {
        title: '新用户',
        value: ['firstBuyerCount', 'firstBuyerRatio'],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '首次付费用户',
            dataIndex: 'firstBuyerCount',
            key: 'firstBuyerCount',
            sorter: true,
            width: 140,
            checked: false
          },
          {
            title: '首次付费占比',
            dataIndex: 'firstBuyerRatio',
            key: 'firstBuyerRatio',
            sorter: true,
            width: 140,
            checked: false
          }
        ]
      },
      {
        title: '退费',
        value: ['refundAmount', 'refundOrderCount'],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '退费金额',
            dataIndex: 'refundAmount',
            key: 'refundAmount',
            sorter: true,
            width: 140,
            checked: false
          },
          {
            title: '退费订单数',
            dataIndex: 'refundOrderCount',
            key: 'refundOrderCount',
            sorter: true,
            width: 140,
            checked: false,
          }
        ]
      }
    ]
    console.log(e.target.checked)
    isCheckAll.value = e.target.checked;
    if (e.target.checked) {
      isIndeterminate.value =false
      let temp = [];
      arr.forEach((item) => {
        item.checkAll = true;
        item.indeterminate = false;
          item.children.forEach((showOption) => {
              temp.push(showOption.title);
          });
      });
      showCheckList.value = temp;
      checkboxList.value = arr;
      emit("getColumn", {list:checkboxList.value,type});
    } else {
      isIndeterminate.value = false;
      showCheckList.value = [];
      checkboxList.value.forEach((item) => {
        item.value = []
        item.checkAll = false;
        item.indeterminate = false;
    });
      emit("getColumn",{list:checkboxList.value,type});
    }
  }
  return {showCheckList,isCheckAll,isIndeterminate,checkboxList,visibleChange,onCheckAllChange,getShowList,handleChange,handleAll}
}